import { useState, useEffect } from 'react';
import { useGlobalContext } from '../../context/context';
import { RiFileCopyLine } from 'react-icons/ri';
import Bitcoin from '../../assets/bitcoin.svg';
import Ethereum from '../../assets/ethereum-eth-logo.svg';
import USDT from '../../assets/tether-usdt-logo.svg';

const Deposit = () => {
  const [depositStep, setDepositStep] = useState(1);
  const { setIsUser, setUserToken, resetPage, fetchUser } = useGlobalContext();

  useEffect(() => {
    setUserToken(JSON.parse(window.localStorage.getItem(`userToken`)));
  }, [setUserToken]);

  useEffect(() => {
    if (window.localStorage.getItem(`userDetails`)) {
      setIsUser(true);
    }
  }, [setIsUser]);

  useEffect(() => {
    resetPage(`Deposit`);
    fetchUser();
    // eslint-disable-next-line
  }, []);

  return (
    <section className='deposit'>
      <section className='deposit-block'>
        {depositStep === 1 ? (
          <StepOne setDepositStep={setDepositStep} />
        ) : (
          <StepTwo />
        )}
      </section>
    </section>
  );
};

const StepOne = ({ setDepositStep }) => {
  const { setDepositAmount, depositAmount, isNavbar } = useGlobalContext();

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <article className='deposit-info'>
      <h3>Make Deposit</h3>
      <form onSubmit={handleSubmit} className='deposit-form'>
        {/* DEPOSIT AMOUNT */}
        <div className='form-control'>
          <input
            type='number'
            placeholder='Enter a amount to deposit in USD (10000)'
            id='amount'
            className='input'
            name='amount'
            value={depositAmount}
            onChange={(e) => setDepositAmount(e.target.value)}
            required
          />
          <label
            htmlFor='amount'
            className={isNavbar ? `navOpen label` : `label`}
          >
            Enter deposit amount in USD
          </label>
        </div>

        {/* MAKE DEPOSIT BUTTON */}
        <div className='submit-btn'>
          <button
            className='purple'
            onClick={() => {
              if (depositAmount > 0) {
                setDepositStep(2);
              }
            }}
          >
            Make Deposit
          </button>
        </div>
      </form>
    </article>
  );
};

const StepTwo = () => {
  const { userToken, depositAmount, baseURL, userDetails } = useGlobalContext();

  const sendDepositRequest = async () => {
    try {
      const response = await fetch(`${baseURL}/transaction/add`, {
        method: `POST`,
        body: JSON.stringify({ amount: Number(depositAmount) }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      });
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (e) => {
    const current = e.target.parentElement.nextSibling;
    current.classList.toggle(`show-address`);
  };

  const handleCopy = (e) => {
    const copyText = e.target.previousSibling.textContent;
    navigator.clipboard.writeText(copyText);
    e.target.nextSibling.classList.add(`show`);
    setTimeout(() => {
      e.target.nextSibling.classList.remove(`show`);
    }, 3000);
  };

  return (
    <article className='receipt'>
      <h1>
        Please select a payment method and make a deposit to the provided wallet
        address.
      </h1>

      {/* PAYMENT METHODS */}
      <div className='method'>
        <div className='crypto' onClick={handleClick}>
          <img src={Bitcoin} alt='bitcoin logo' />
          <h4>Bitcoin</h4>
        </div>
        <div className='wallet-address'>
          <p>
            {userDetails.bitcoinAddress.length < 2
              ? `bc1qrgr54uxh56d79gq0zgzftxprhngt0hlsclr8na`
              : userDetails.bitcoinAddress}
          </p>
          <RiFileCopyLine className='copy-btn' onClick={handleCopy} />
          <p className='confirmation'>Copied</p>
        </div>
      </div>

      <div className='method'>
        <div className='crypto' onClick={handleClick}>
          <img src={Ethereum} alt='ethereum logo' />
          <h4>Ethereum</h4>
        </div>
        <div className='wallet-address'>
          <p>
            {userDetails.ethereumAddress.length < 2
              ? `0x5EeA70Af643e3340E5505dC349BD3c7A718CB6FE`
              : userDetails.ethereumAddress}
          </p>
          <RiFileCopyLine className='copy-btn' onClick={handleCopy} />
          <p className='confirmation'>Copied</p>
        </div>
      </div>

      <div className='method'>
        <div className='crypto' onClick={handleClick}>
          <img src={USDT} alt='usdt logo' />
          <h4>USDT</h4>
        </div>
        <div className='wallet-address'>
          <p>
            {userDetails.usdtAddress.length < 2
              ? `TTtPx76jS9mpVYE95L52c6sDtVh354C17C`
              : userDetails.usdtAddress}
          </p>
          <RiFileCopyLine className='copy-btn' onClick={handleCopy} />
          <p className='confirmation'>Copied</p>
        </div>
      </div>

      <p>
        After sucessfully making payment, ensure to submit payment receipt via
        email by clicking on the button below.
      </p>
      <a href='mailto: support@geminitetherchain.com?subject=Deposit Receipt'>
        <button
          className='purple'
          onClick={() => {
            setTimeout(() => {
              sendDepositRequest();
            }, 2000);
          }}
        >
          Upload Payment Receipt
        </button>
      </a>
    </article>
  );
};

export default Deposit;
