import { Link } from "react-router-dom";
import { planPrices } from "../../data/data";
import { FcCheckmark } from "react-icons/fc";
import { useGlobalContext } from "../../context/context";

// IMAGES
import BeginJourney from "../../assets/begin-journey.webp";
import { useEffect } from "react";

const Prices = () => {
  const { resetPage } = useGlobalContext();

  useEffect(() => {
    resetPage(`Crypto Investment Prices`);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <section className='prices'>
        <h1>Pricing Plans</h1>
        <div className='prices-cards'>
          {planPrices.map((plan, index) => {
            const { planName, planPrice, planFeatures } = plan;
            return (
              <div className='card' key={index}>
                <h3>{planName}</h3>
                <h3 className='price'>{planPrice}</h3>
                <p className='feature-comp'>Top feature comparison</p>
                <ul className='features-list'>
                  {planFeatures.map((feature, index) => {
                    return (
                      <li key={index}>
                        <span>
                          <FcCheckmark />
                        </span>{" "}
                        {feature}
                      </li>
                    );
                  })}
                </ul>

                <Link to='/sign-in'>
                  <button className='purple'>Purchase Plan</button>
                </Link>
              </div>
            );
          })}
        </div>
        <Block extraClass={`support-team`}>
          <div className='text'>
            <h2>Start your crypto journey</h2>
            <h5>
              GeminiTetherChain makes it easy to get started. Sign up today to
              buy and sell 185+ cryptocurrencies.
            </h5>
            <div className='btn'>
              <Link to='/sign-up'>
                <button className='purple'>
                  Get Started with GeminiTetherChain
                </button>
              </Link>
            </div>
          </div>
          <div className='image'>
            <img src={BeginJourney} alt='get started' />
          </div>
        </Block>
      </section>
    </>
  );
};

const Block = ({ children, extraClass }) => {
  return <section className={`block ${extraClass}`}>{children}</section>;
};

export default Prices;
