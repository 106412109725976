import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='grid'>
        <div className='item'>
          <p>Take your crypto to the next level with GeminiTetherChain.</p>
          <div className='btns'>
            <button type='button' className='white'>
              <Link to='/sign-up'>Create account</Link>
            </button>
            <button type='button'>
              <Link to='/sign-in'>Sign in</Link>
            </button>
          </div>
        </div>
        <div className='item'>
          <h4>Features</h4>
          <ul>
            <li>
              <Link to='/services'>Services</Link>
            </li>
            <li>
              <Link to='/guides'>Guides</Link>
            </li>
          </ul>
        </div>
        <div className='item'>
          <h4>Prices</h4>
          <ul>
            <li>
              <Link to='/prices'>Prices</Link>
            </li>
          </ul>
        </div>
        <div className='item'>
          <h4>Learn</h4>
          <ul>
            <li>
              <Link to='/guides'>Crypto Guides</Link>
            </li>
            <li>
              <Link to='/terms-of-use'>Terms of Use</Link>
            </li>
            <li>
              <Link to='/privacy-policy'>Privacy Policy</Link>
            </li>
          </ul>
        </div>
        <div className='item'>
          <h4>About</h4>
          <ul>
            <li>
              <Link to='/about'>About</Link>
            </li>
            <li>
              <Link to='/contact'>Contact</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className='copyright'>&copy; 2016 GeminiTetherChain.com</div>
    </footer>
  );
};

export default Footer;
